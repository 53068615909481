import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { APPLICATION_ENV, APPLICATION_VERSION } from "./lib/applicationVersion";

Sentry.init({
    dsn: "https://ee8de3f452f937e42fe23e5832fa7950@o4506010189299712.ingest.us.sentry.io/4507221829091328",
    release: "oversite-api-v2@" + APPLICATION_VERSION,
    environment: APPLICATION_ENV,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    tracePropagationTargets: ['https://api-v2-staging.addoversite.com/', 'https://api-v2.addoversite.com/'],
    allowUrls: [/https?:\/\/((api-v2|api-v2-staging)\.)?addoversite\.com/],
    integrations: [Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }), Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()]
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});